import React from 'react'
// import { Job } from '../components/Job'
// import { SEO } from '../components/SEO'
// import { JobLayout } from '../layouts/JobLayout'
import { Job as JobType } from '../../utils/greenhouse'
import JobPageTemplate from '../../templates/JobPageTemplate'

const ProductManager = () => {
  const job: JobType = {
    id: '2',
    title: "Senior Product Manager",
    location: {
        name: "Poznań, Poland"
    },
    metadata: [
      {
        value: {
          min_value: "22500",
          max_value: "37000",
          unit: "PLN"
        },
        value_type: "",
        name: "Salary Range"
      }
    ],
    emailApply: true,
    ogImage: "https://jobs.fourthwall.com/images/og/product-leader.jpg",
    content: `
      <div class="article">
        <div>
          <p><strong>Job Summary</strong></p>
          <p><span style="font-weight: 400;">We seek a visionary Senior Product Manager to drive our product strategy and execution. You'll work directly with 6-8 engineers (out of our exceptional team of 40+ engineers) and collaborate with cross-functional teams to deliver innovative solutions for content creators. You can work remotely, from our beautiful office in Poznań, or some mix of the two—it's up to you.</span></p>
        </div>

        <div>
          <p><strong>What we expect:</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Proven track record in product management, preferably in e-commerce or creator economy platforms,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Expert knowledge of agile methodologies and product development lifecycles,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Strong analytical skills with experience in data-driven decision-making and behavior analytics tools,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Ability to balance strategic thinking with tactical execution, showcasing your knack for innovation and self-directed project management,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Excellent communication skills, openness to conversations, and ability to influence stakeholders at all levels,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">English language proficiency to allow accessible communication both in writing and speech,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Experience in working with tools such as Jira, analytics platforms (Google Analytics, BigQuery, FullStory, Clarity) and product management software,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Familiarity with SQL, modern tech stacks, cloud platforms, and AI-powered tools will be an additional advantage.</span></li>
          </ul>
        </div>

        <div>
          <p><strong>Perks:</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Salary 22 500 - 37 000 PLN (paid in USD, + VAT 0% as you'll be exporting your services to the US),</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Equity in our US-based company,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Remote work, flexible working hours, and access to our modern and comfortable office in Poznań (Jeżyce),</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">B2B agreement for an indefinite period with 26 days of paid vacation per year, health-related absence policy,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">An exciting and demanding project that you have a real influence on,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Work in a team of experienced experts and amazing people,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">The latest standards of used tools - current and supported frameworks, without working on outdated strategies,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Employer-paid perks (MultiSport, LUX MED + dental care, accounting support, educational budget, English lessons, team integrations, four months parental allowance),</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">MacBook Pro and any other equipment tailored to your needs.</span></li>
          </ul>
        </div>

        <div>
          <p><strong>Requirements:</strong></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Develop and execute product strategy aligned with company goals and market needs,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Ship a best-in-class product experience to over 100k creators,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Triage and prioritize key fixes to ensure customers are not impacted,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Lead a cross-functional team that ships code daily to deliver the highest-quality experience,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Conduct regular product performance reviews and leverage behavior analytics for data-informed decisions,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Manage stakeholder expectations and communicate product strategy effectively,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Optimize resource allocation across teams to maximize productivity and value delivery,</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Drive innovation through product discovery workshops and stay current with market trends.</span></li>
          </ul>
          <p><span style="font-weight: 400;"><strong>This role requires occasional synchronous work with US-based team members, typically no later than 10 AM Pacific Time (7 PM Central European Time / Polish Time), once or at most twice weekly</strong>. Please ensure you can accommodate this requirement before applying.</span></p>
        </div>

        <div>
          <p><strong>About us:</strong></p>
          <p><span style="font-weight: 400;">Fourthwall gives creators the freedom to focus on creating. Over 100,000 creators are using Fourthwall to power their shops and memberships.</span></p>
          <p><span style="font-weight: 400;">We enable creators (YouTubers, Instagrammers, Podcasters, etc.- anyone with a large online audience) to make money by opening beautiful, fully customized online shops where they can sell merchandise, accept donations, and offer memberships. Our mission is to make the money-making side of content creation easy and let creators focus on what matters: creating great content and interacting with their fans.</span></p>
          <p><span style="font-weight: 400;">Our culture is built around putting our customers, the creators, first. If you are talented, hardworking, and love helping others, we would love to talk to you about joining our team!</span></p>
          <p><span style="font-weight: 400;">By choosing our offer, you can join a team of experts in application architecture, programming languages (Kotlin and Ruby enthusiasts), and, most importantly, extensive e-commerce business experience.</span></p>
          <p><span style="font-weight: 400;">If you want to learn more about us, and how we work, you can peek at <a href="https://jobs.fourthwall.com" target="_blank">jobs.fourthwall.com</a>. We also suggest that you get to know our <a href="https://cdn.fourthwall.com/public/fourthwall/Fourthwall%20Values.pdf" target="_blank">Company Values</a>.</span></p>
        </div>

        <div>
          <p><strong>Recruitment process:</strong></p>
          <ol>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Sending CV + initial phone call (20 - 45 minutes)</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Recruitment task carried out at a time selected by you (remotely), we will pay 1000 PLN net for your time (regardless of the interview result)</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Meeting (in person or remotely) at the Fourthwall HQ with our team (w/2-3 engineers from our team) (1-2 hours)</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">Your and our decision within three business days</span></li>
          </ol>
        </div>

        <div>
          <p><span style="font-weight: 400;"><strong>It's also worth noting that we encourage anyone who may be interested to apply, even if you don't meet 100% of the qualifications above.</strong> We may have overlooked, under-counted, or over-indexed on some of these qualifications. So, please — apply. You know what they say about missing shots...</span></p>
          <p><span style="font-weight: 400;"><em data-renderer-mark="true">We are an equal-opportunity employer. We value a diverse workforce and an inclusive culture. We encourage applications from all qualified individuals without regard to race, color, religion, gender, sexual orientation, gender identity or expression, age, national origin, marital status, disability, and veteran status.</em></span></p>
        </div>
      </div>
    `
  }

  return (
    <div>
      <JobPageTemplate pageContext={{
          job
      }} />
    </div>
  )
}

export default ProductManager